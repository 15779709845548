.whos-levit-container{
  background-image: url("./whos_levit_background.png");
  padding-bottom: 3em;
}
.levit-img-for{
    height: 20px;
    margin-top: -6px;
    margin-left: 0.02em;
    margin-right: 0.02em;
  }
  .levit-content-for{
    font-size:1.8rem ;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
    }
    .levit-for-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .levit-for-card{
      height: 10em;
      width: 26%;
      border: 1px solid #EB5F46;
      border-radius: 10px;
      margin: 2em 1.5em;
      position: relative;
      cursor: context-menu;
      padding-left: 1em;
      padding-right: 1em;
    }   
    .whos-levit-icon{
      position: absolute;
      height: 3em;
      width: 3em;
      transform: translate(-1.5em, -1.5em);
    }
    .levit-for-card:hover{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border: 1px solid #fff;
      background-color: #fff;
    }
    .levit-for-header{
      font-size: 0.8rem;
      font-weight: 600;
      margin-top: 3em;
    }
    .levit-for-txt{
      font-size: 0.8rem;
    }
    .i-need-this-btn{
      background: #EB5F46;
      box-shadow: 0px 0px 10px rgba(235, 95, 70, 0.53);
      border-radius: 6px;
      padding: 6px 20px;
      border: 0px;
      color: #fff;
      font-weight: 600;
      font-size: 0.8rem;
      margin-top: 1.5em;
      cursor: pointer;
    }
    .who-is-main-container{
      padding-top: 4em;
      padding-bottom: 3em
    }
    .featured-container{ 
      margin-top:2.5em;
    }
    .featured-header{
      margin-bottom: 2.5em;
      font-weight: 700;
      font-size: 1rem;
      text-align: center;
      letter-spacing: 0.1rem;
    }
    .featured-image-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      padding-left: 4em;
      padding-right: 4em;
    }
    .featured-image{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 8px;
    }

    @media only screen and (max-width: 850px) {
      .whos-levit-container{
        background-image: none;
      }
      .who-is-main-container{
        background-color:#F9FAFD;     
        padding-left: 1.5em !important;
        padding-right: 1.5em !important;
      }
      .levit-for-container{
        flex-wrap: nowrap !important;
        overflow-x: auto;
        justify-content: flex-start !important;
      }
      .levit-for-card{
        min-width: 75% !important;
        height: 11em !important;
      }
      .i-need-this-btn{
        display: none;
      }
      .featured-image-container{
        padding-left: 1em !important;
        padding-right: 1em !important;
      }
      .featured-image{
        margin: 0.5em !important;
        box-shadow: rgb(100 100 111 / 20%) 1px 1px 8px 2px
      }
    }