
footer{
    background-color: #0C1924;
    color: #fff;
    padding: 2em 0em;
  }
  
  .logo1{
    height:3em;
    width: 12em;
  }
  .img1{
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .list{
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }
  .list a{
    display: flex;
    text-decoration: none;
    font-weight: bold;
    padding: 1.5em;
    color: #fff;
  }
  .list a:hover{
    color: #fff !important;
  }

  .icons{
  
        display: flex;
        margin: 0.5rem ;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 2.5em;
  }
  
  .social{
    margin: 0.3em;
    height: 2em;
    width: 2em;
  }
  hr{
    margin-top: 5em;
  }
  
  .lower{
    padding-right: 15em;
    justify-self: flex-end;
  }
  .lower a{
    text-decoration: none;
    color: #fff;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .lower a:hover{
    color: #fff;
  }
  .xl{
    font-size: 2.5rem;
  }
  .lower-footer{
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 0.8rem;
    justify-content: flex-end;
  }
  .copyright{
    padding-left: 15em;
    justify-self: flex-start;
  }
  
  @media only screen and (max-width: 850px) {
 .list a{
  padding: 0.5em !important;
  font-size: 0.6rem !important;
 }
 .logo1 {
  height: 2em !important;
  width: 8em !important;
}

.list{
  margin-top: 0.6em !important;
}
.social{
  height: 1.2em !important;
  width: 1.2em !important;
}
.icons{
  margin-bottom: 0em !important;
}
.footer-line{
  display: none;
}
.copyright{
  padding-left: 1em !important;
  font-size: 0.4rem !important;
  align-self: center;
}
.lower{
  padding-right: 1em !important;
}
.lower a{
  font-size: 0.4rem !important;
}
 
  }