.levit-container{
    display: flex;
    flex-direction: row;
    padding-top: 5em;
    padding-left: 8em;
    padding-bottom: 8em;
    background-color: #F5F5F5;
    background-image: url("./what_is_levit_back.png");
}
.levit-mobile-back{
  display: none;
}
.levit-img-2{
  height: 20px;
  margin-top: -6px;
  margin-left: 0.04em;
  margin-right: 0.04em;
}
.levit-content1{
  font-size:1.8rem ;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-align: start;
  }

.levit-container-item{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 4em;
    padding-right: 5em;
}
.levit-list-item{
    display: flex;
    flex-direction: row;
    padding-top: 1em;
}
.hexagon{
    position: relative;
    text-align: center;
    color: white;
    height: fit-content;
}
.txt-hex{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.7rem;
    font-weight: 700;
  }
  .levit-content4{
    text-align: start;
    margin-left: 1em;
    font-size: 0.9rem;
  }
  .levit-img-1{
    height: 28em;
    border-radius: 10px;
  }
  .get-this-now-btn{
    background: #EB5F46;
    box-shadow: 0px 0px 10px rgba(235, 95, 70, 0.53);
    border-radius: 6px;
    padding: 6px 20px;
    border: 0px;
    color: #fff;
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
    
  }
  .levit-content1{
    text-align: start;
  }
  .levit-btn{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 4em;
  }
  @media only screen and (max-width: 850px) {
    .levit-container{
      flex-wrap: wrap;
      padding-left: 2em !important;
      padding-right: 2em !important;
      padding-bottom: 3em !important;
      background-image: none;
      background-color: #fff;
      padding-top: 0em !important;
    }  
    .levit-mobile-back{
      display: block;
      width: 100% !important;
      margin-bottom: 1em;
    }
    .levit-image{
      width: 100%;
    }
    .levit-container-item{
      width:100% !important;
      margin-left: 0em !important;
      padding-right: 2em !important;
      margin-top: 2em;
    }
    .levit-img-1{
      height: 18em !important;
    }
    .levit-content1{
      align-self: center !important;
    }
    .levit-btn{
      display: none;
    }
  }
  