.who-are-container-items{
    display: flex;
    width:50%;
    flex-direction: column;
    padding-left: 8em;
    padding-right: 2.5em;
    align-items: flex-start;
}
.who-are-we-heading{
    font-weight: 700;
}
.who-are-get-btn{
    background: #EB5F46;
    box-shadow: 0px 0px 10px rgba(235, 95, 70, 0.53);
    border-radius: 6px;
    padding: 6px 20px;
    border: 0px;
    color: #fff;
    font-weight: 600;
    font-size: 0.8rem;
    margin-top: 1em;
    cursor: pointer;
}
.who-are-we-image{
    width: 50%; 
    padding-right: 2em;
}
.who-are-we-container{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 6em;
    margin-bottom: 6em;

}
.who_are_we_image1{
    height: 26em;
    width:35em;
}
.who-are-items1{
    text-align: start;
    font-size:1rem;
    color: #7A7A7A;
}
.who-get-btn-wrapper-mobile{
    display: none;
}
@media only screen and (max-width: 850px) {
    .who-are-we-container{
        padding: 0em 1.5em;
        justify-content: center;
        margin-top: 3em !important;
        margin-bottom: 3em !important;
    }
    .who-are-container-items{
        width: 100%;
        padding: 0em !important;
        align-items: center !important;
    }
    .who-are-we-image{
        width: 100%;
        padding: 0em !important;
        margin-top: 1em !important;
    }
    .who_are_we_image1{
        height: 14em !important;
        width: 18em !important;
    }
    .who-get-btn-wrapper-web{
        display: none;
    }
    .who-get-btn-wrapper-mobile{
        display: block;
        margin-top: 1em !important;
    }
    .who-are-we-heading{
        margin-bottom: 1em !important;
        
    }
}