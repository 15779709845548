.our-gallery-header{
    color: #000000;
    letter-spacing: 0.02em;
    font-weight: 650;
    font-size: 2.1rem;
    word-spacing: 0.04em;
}
.our-gallery{
  margin-bottom: 4em;
}
.our-gallery-text{
    color: #7A7A7A;
    text-align: center;
    letter-spacing: 0.02em;
    font-size: 0.81rem;
    font-weight: 500;
    padding-left: 23em;
    padding-right: 23em;
    margin-top: 1em;
}
input {

    display: none;
  }
  .carousel-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
  }
  .container {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 600px;
    display: flex;
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .dot-container{
    display: flex;
    justify-content: space-evenly;
  }
  .dot-container img{
    margin: 0.4em;
    height: 8px;
    width: 8px;
    cursor: pointer;
  }
  .dot-container .arrow{
    height: 10px;
    width: 12px;
  }
  .cards {
    position: relative;
    height: 20em;
    width: 40em;
    margin-bottom: 20px;
    perspective: 1000px;
    transform-style: preserve-3d;
  }
  
  .cards label {
  
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    transition: transform 0.30s ease;
    border-radius: 10px;
  }
  
  .cards .card1{
  
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
  
  .card1 .image{
  
    display: flex;
    justify-content: space-between;
  }
  
  .card1 .image img{
  
    border-radius: 10px;
    height: 20em;
    width: 40em;
    object-fit: cover;
  }
  
  #s1:checked~.cards #slide4,
  #s2:checked~.cards #slide5,
  #s3:checked~.cards #slide1,
  #s4:checked~.cards #slide2,
  #s5:checked~.cards #slide3 {
      transform: translate3d(-60%, 0, -480px); 
      /* 2nd left */
  }
  
  #s1:checked~.cards #slide5,
  #s2:checked~.cards #slide1,
  #s3:checked~.cards #slide2,
  #s4:checked~.cards #slide3,
  #s5:checked~.cards #slide4 {
      transform: translate3d(-30%, 0px, -210px);
      /* 1st left */
  }
  
  #s1:checked~.cards #slide1,
  #s2:checked~.cards #slide2,
  #s3:checked~.cards #slide3,
  #s4:checked~.cards #slide4,
  #s5:checked~.cards #slide5 {
      transform: translate3d(0, 0, 0);
  }
  
  #s1:checked~.cards #slide2,
  #s2:checked~.cards #slide3,
  #s3:checked~.cards #slide4,
  #s4:checked~.cards #slide5,
  #s5:checked~.cards #slide1 {
      transform: translate3d(30%, 0px, -210px);
      /* 1st right */
  }
  
  #s1:checked~.cards #slide3,
  #s2:checked~.cards #slide4,
  #s3:checked~.cards #slide5,
  #s4:checked~.cards #slide1,
  #s5:checked~.cards #slide2 {
      transform: translate3d(60%, 0, -480px);
      /* 2nd right */
  }

  @media only screen and (max-width: 850px) {
    .our-gallery{
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
    .our-gallery-header{
      font-size: 1.6rem !important;
    }
    .our-gallery-text{
      padding-left: 0em !important;
      padding-right: 0em !important;
    }
  }
  @media only screen and (max-width: 450px) {
    .carousel-wrapper{
      margin-top: 3em !important;
    }
    .container{
      max-width: 350px;
      max-height: 400px;
    }
    .cards{
      height: 150px;
      width:280px;
    }
    .card1 .image img{
      height: 150px;
      width:280px;
    }
  }