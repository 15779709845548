.users-say{
    display: flex;
    flex-direction: column;
    background-image: url("./users_say_back.png");
    background-repeat: repeat;
    padding-top: 2.5em;
}
.users-say-item{
    font-size:1.95rem ;
    color: #000000;
    letter-spacing: 0.02em;
    word-spacing: 0.02em;
    font-weight: 700;
}
.users-say-card{
    display: flex;
    flex-direction: column;
    width: 25%;
    text-align: start;
    background: #FFFFFF;
    padding: 1.5em;
    margin: 1.5em;
    border-radius: 15px;
}
.users-say-card:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.users-say-card-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 0.5em;
    margin-bottom: 3em;  
}
.users-say-header{
font-size: 1.2rem;
color: #080808;
font-weight: 700;
padding-top: 2em;
}
.users-say-text{
    font-size: 0.85rem;
    padding-right: 0.3em;
    padding-top: 1.5em;
}
.users-say-line{
    height: 2px;
    background-color: #E6E6E6;
}

.user-rating-wrapper{
    display: flex;
    margin-top: 0.5em;
    justify-content: flex-start;
    align-items: center;
}
.user-ratings{
    display: flex;
    width: 50%;
}
.user-rating-wrapper p{
    font-size: 1rem;
    font-weight: 400;
    align-self: flex-end;
    width: 50%;
    margin: 0px;
    text-align: end;
}
.user-ratings img{
    margin-left: 0.1em;
    margin-right: 0.1em;
    height: 1em;
    width: 1em;

}
.say-icon{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    margin-right:1.5em;
    
}
.say-icon img{
    height: 2.5em;
    width: 2.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    cursor: pointer;
}
.users-say-icon1{
    margin-top: 0.8em;
}

@media only screen and (max-width: 850px) {
    .users-say{
        padding: 0em 1.5em !important;
    }
    .users-say-item{
        font-size: 1.6rem !important;
    }
    .say-icon{
        display: none;
    }
    .users-say-card-container{
        overflow-x: auto;
        justify-content: flex-start !important;
    }
    .users-say-card{
        width: 100% !important;
        min-width: 15em;
    }
    .users-say-header{
        padding-top:  1em !important;
    }
    .users-say-text{
        padding-top: 1em !important;
    }
    .user-ratings img {
        height: 0.8em !important;
        width: 0.8em !important;
    }
    .user-rating-wrapper p {
        font-size: 0.8rem !important;
    }
}