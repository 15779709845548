
.nav-header-text{
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 0.8rem !important;
}
.nav-logo{
    margin-left: 2em !important;
}
.nav-item-wrapper{
    margin-left: 3.4em !important;
}
.nav-item{
    margin: 0em 0.6em;
}
.nav-btn{
    background: #EB5F46;
    box-shadow: 0px 0px 10px rgba(235, 95, 70, 0.53);
    border-radius: 6px;
    margin-left: 4em;
    padding: 6px 20px;
    border: 0px;
    color: #fff;
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
}
.navbar .container{
    flex-direction: row !important;
}

@media only screen and (max-width: 850px) {
    .navbar .container{
        justify-content: center !important;
    }
    .navbar-brand{
        padding-top: 1em !important;
        padding-bottom: 1em !important;
        margin-left: 0px !important;
    }
}