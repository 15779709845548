.opening-guide-levit-header{
    font-weight: 600; 
    font-size: 1.5rem;
    color: #FFFFFF;
}
.opening-guide-levit{
    background-image: url("./opening_back.png");
    display: flex;
    flex-direction: column;
    padding-top: 4em;
    padding-bottom: 8em;
}
.opening-guide-levit-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 3em;
    padding-left: 3em;
    padding-right: 3em;
    align-items: center;
}

.opening-guide-levit-img1{
    height: 0.75em;
    margin-top: -4px;
    margin-left: 2px;
}
.opening-guide-item1{
    color:#E3E3E3;
    font-weight: 500;
    font-size: 0.8rem;
    
}
.opening-guide-card{
    height:20em;
    width:20%;
    background-color: #FFFFFF;
    color: #4E4E4E;
    border-radius: 10px;
   padding-top: 1em;
    

}
.opening-guide-header{
    color:  #000000;
    font-size: 0.8rem;
    margin-top: 1.5em;
}
.opening-guide-text{
    color: #4E4E4E;
    font-weight: 400;
    letter-spacing: 0.02em;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    word-spacing: 0.01em;
   font-size: 0.8rem;
    
}
@media only screen and (max-width: 850px) {
    .opening-guide-levit-container{
        flex-wrap: wrap !important;
        padding-left: 5.5em !important;
        padding-right: 5.5em !important;

    }
    .opening-guide-card{
        width: 100% !important;
        height: auto !important;
    }

    .opening-guide-img1{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .opening-guide-levit{
        padding-bottom: 4em !important;
    }
}
