.standing-desk{
    background-image: url("./standing_desk_back.png");
    padding-bottom: 7em;
 }
 .standing-desk-text{
   margin-top: 1.5em;
   font-size: 0.81rem;
   color:#7A7A7A;
   font-weight: 500;
   padding-left: 29em;
   padding-right: 29em;
   text-align:center;
 }
 .standing-desk-header{
    font-size: 1.9rem;
    color:#000000;
    font-weight: 690;
    letter-spacing: 0.02em;
    word-spacing: 0.04em;
 }

 .video-wrapper{
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 4em;
 }
 .video-wrapper video{
   border-radius: 10px;
 }
 .react-player__preview{
   border-radius: 12px;
 }
.standing-desk-video{
  width: 683px;
  height: 24em;
}
 @media only screen and (max-width: 850px) {
  .standing-desk{
    padding-left: 1.5em ;
    padding-right: 1.5em;
    padding-bottom: 4em !important;
  }
  .standing-desk-header {
    font-size: 1.5rem !important;
  }
  .standing-desk-text{
    padding-left: 3em !important;
    padding-right: 3em !important;
  }
  .video-wrapper{
    margin-top: 2em !important;
  }
  .standing-desk-video{
    width: 341px !important;
    height: 12em !important;
  }
 }