.why-levit{
    display: flex;
    flex-direction: column;
    padding-top: 5em;
    padding-bottom: 3em;
    background: #F9FAFD;
}
.why-levit-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.why-levit-card{
    width: 22%;
    height: 13em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding:1em;
    margin: 3em;
    background-color:#fff;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.why-levit-card:hover{
    border-top: 6px solid #EB5F46;
    border-bottom: 6px solid #EB5F46;
    cursor: default;
    padding-top: calc(1em - 6px);
    padding-bottom: calc(1em - 6px);
}
.why-levit-header{
    font-size: 1.1em;
    font-weight: 600;
    margin-top: 0.8em;
}
.why-levit-header1{
    color: #000000;
    font-weight: 700;
    letter-spacing: 0.01em;
}
.why-levit-para{
    color: #7A7A7A;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.02em;
    font-size: 0.9rem;
    margin-top: 1em;
    margin-left: 22em;
    margin-right: 22em;
}
.levit-img-4{
    height: 0.67em;
    margin-top: -8px;
    margin-left: 2px;
}
.why-levit-text{
    font-size: 0.8rem;
    margin-top: 1em;
}
.btn-order-now1{
    padding: 8px 20px;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 12px;
    background-color: #EB5F46;
    color: #fff;
    border: 0px;
    box-shadow: 0px 7px 11px rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 850px) {

    .why-levit-para{
        margin-left: 0em !important;
        margin-right: 0em !important;
        
    }
    .why-levit-container{
    flex-wrap: nowrap !important;
    overflow-x: auto;
    justify-content: flex-start !important;
    }
    .why-levit-card{
        min-width: 85% !important;
        height: 14em !important;
        margin:2em 1em !important;
    }
    .why-levit{
        padding-left: 2em !important;
        padding-right: 2em !important;
    }
}