.innovative-container{
   margin-top: 4em;
   display: flex;
   justify-self:center;
   align-items: center;
   flex-direction: column;
   margin-bottom: 4em;
   font-family: 'Rajdhani';
}
.innovative-header{
   font-weight: 700;
   font-size: 1.9rem;
   letter-spacing: 0.01em;
   color: #000000;
}
.innovative-text{
    margin-top: 1.6em;
    letter-spacing: 0.01em;
    font-weight: 500;
    font-size: 0.9rem;
    color: #7A7A7A;
    margin-left: 23em;
    margin-right: 23em;
    flex-wrap: wrap;

}
.ip-time-txt{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.ip-time-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5em;
}
.ip-hr{
    font-weight: 600;
    font-size: 1.4rem;
    }
    .ip-title{
        font-size: 0.4rem;
    
    }
    .ip-sale{
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.2rem;
        margin-right: 1em;
    }
    .inn-wrapper{
        display: flex;
        width: 100%;
    }
    
.innovative-image{
    margin-bottom: 4em;
    margin-top: 3em;
    position: relative;
    height: 28em;
    width: 27.5em;
    max-width: 50%;
    margin-left: 12em;
}
.innovative-img-main{
    height:28em;
    width:27.5em;
}
.off-img{
    position: absolute;
    top: 1.9em;
    right: 0em;
    height: 4em;
}
.img-durable{
    position: absolute;
    left: 7em;
    top: 4em;
    height: 1.5em;
    width: 1.5em;
    -webkit-animation: pulse 1.5s infinite;
    animation: pulse 1.5s infinite;
    cursor: pointer;
}
.img-grabit{
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    bottom: 10em;
    left: 8.6em;
    -webkit-animation: pulse 1.5s infinite;
    animation: pulse 1.5s infinite;
    cursor: pointer;
}
.img-water{
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    top: 10em;
    right: 7.6em;
    -webkit-animation: pulse 1.5s infinite;
    animation: pulse 1.5s infinite;
    cursor: pointer;
}
.img-durability{
    position: absolute;
    height: 1.5em;
    width: 1.5em;
    bottom: 2em;
    right: 10.8em;
    -webkit-animation: pulse 1.5s infinite;
    animation: pulse 1.5s infinite;
    cursor: pointer;
}
@keyframes pulse {
    0% {
      transform: scale(.6);
    }
    25%{
        transform: scale(0.8);
        box-shadow: 0 0 0 50px rgba(#5a99d4, 0);  
    }
    50% {
     transform: scale(1);
      box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
    }
    75%{
        transform: scale(1.2);
        box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
    }
      100% {
     transform: scale(1.3);
      box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
  }
  .pop-durable-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.pop-durable-content1{
    display: flex;
    flex-direction: column;
    text-align: end;
    padding-right: 1em;
}

.pop-durable-content2{
    height: 3em;
    width: 3em;
}
.pop-header{
    font-size: 1.2em;
    font-weight: 600;
}
.pop-text{
    font-size: 0.8em;
    font-weight: 400;
}

.pop-grab-it-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}
.pop-grab-it-content1{
    display: flex;
    flex-direction: column;
    text-align: end;
    padding-right: 1em;
}
.pop-grab-it-content2{
    height: 3em;
    width: 3em;
}

.pop-water-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}
.pop-water-content1{
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-left: 1em;
}
.pop-water-content2{
    height: 3em;
    width: 3em;
}
.pop-header1{
    font-size: 1.2em;
    font-weight: 600;
}
.pop-text1{
    font-size: 0.8em;
    font-weight: 400;
}


.pop-durability-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}
.pop-durability-content1{
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-left: 1em;
}
.pop-durability-content2{
    height: 3em;
    width: 3em;
}
.pop-header1{
    font-size: 1.2em;
    font-weight: 600;
}
.pop-text1{
    font-size: 0.8em;
    font-weight: 400;
}
@media only screen and (max-width: 850px){
    .innovative-text{
        margin-left: 0em !important;
        margin-right: 0em !important;
    }
    .innovative-container{
        padding-left: 2em !important;
        padding-right: 2em !important;
    }
    .innovative-img-main{
        height: 22em !important;
        width: 20em !important;
    }
    .off-img{
        top: 1.4em !important;
        right: -1em !important;
    }
    .img-durable{
        left: 4em !important;
        top: 3em !important;
    }
    .img-grabit{
        left:6em !important;
        bottom: 12em !important;
    }
    .img-water{
        top: 7em !important;
        right: 6.5em !important;
    }
    .img-durability{
        bottom: 7em !important;
        right: 7.5em !important;
    }
    .innovative-image{
        margin-bottom: 0em !important;
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0em !important;
    }
    .ip-sale{
        width: 100% !important;
    }
    .ip-time-txt{
        justify-content: center !important;
    }
    .innovative-text{
        display: none !important;
    }
    .innovative-header{
        font-size: 1rem !important;
        margin-bottom: 1em !important;
    }
    .inn-wrapper{
        flex-wrap: wrap !important;
    }
    .buy-wrapper{
        width: 100% !important;
        margin-left: 0em !important;
        margin-top: 0em !important;
        text-align: center !important;
    }
    .buy-txt{
        font-size: 1.9rem !important;
    }
    .buy-price1{
        font-size: 1.5rem !important;
    }
    .pdt-img-container{
        display: none !important;
    }
    .ic-wrapper{
        justify-content: center !important;
    }
    .btn-innova-order{
        padding: 8px 60px !important;
        font-size: 0.8rem !important;
        margin-bottom: 0.5em !important;
    }

}

.buy-wrapper{
    width: 50%;
    text-align: start;
    margin-left: 11em;
    margin-top: 3em;
}
.buy-txt{
    font-size: 2.2rem;
    color: #ED583D;
    font-weight: 600;
    font-family: 'Rajdhani';
    font-style: normal;
}
.buy-price1{
    color: #000000;
   
}
.buy-price2{
    color: #7B7B7B;
    text-decoration-line: line-through; 
}
.pdt-img-container{
    display: flex;
    flex-direction: row;
}
.pdt-img1{
    height: 5em;
    border-radius: 4.032px;
    margin: 0em 0.3em;
}
.ic-wrapper{
    display: flex;
}
.ic{
    border:1px solid #636060;
    background-color: #EB5F46B8;
    border-radius: 4.032px;
    height: 2.2em;
    width: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0.3em;
    cursor: pointer;
}
.ic1{
    border:1px solid #636060;
    border-radius: 4.032px;
    height: 2.2em;
    width: 2.2em;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 1em 0.3em;
}
.ic img{
    height: 1em;
    width: 1em;
}

.btn-innova-order{ 
    padding: 8px 100px;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #EB5F46;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.buy-btn{
    display: flex;
    flex-direction: column;
}
.buy-btn1{
    margin-bottom: 0.4em;
}
.btn-img{
    height: 1.6em;
    margin-left: 0.5em;
}




