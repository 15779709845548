.contact-with-container{
    padding-top: 1.7em;
    padding-bottom: 2.5em;
}
.contact-us-body{
    display: flex;
    flex-direction: row;
}
.contact-with-item1{
    width: 60%;
    padding-left: 3em;
}
.contact-with-item2{
    width: 40%;
    
}
.contact-with-header{
    letter-spacing: 0.02em;
    color: #000000;
    font-size: 3.1em;
    font-weight: 700;
    text-align: start;
    padding-left: 3em;
}
.contact-with-card{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: start;
    background: #FFFFFF;
    padding: 2em;
    margin: 0.5em;
    border: 1px solid#E9ECED;
    border-radius: 15px;
    width: 22em;
    margin-top: 1em;
}
.btn1-txt1{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1em;
}
.txt-extra-info{
    font-size: 0.7rem;
    text-align: center;
    margin: 0px;
    padding: 0px;
    margin-left: 0.5em;
}
.btn-send-message{
                    text-decoration: none;
                    background: #EB5F46;
                    box-shadow: 0px 0px 10px rgba(235, 95, 70, 0.53);
                    border-radius: 5px;
                    padding: 18px 70px;
                    border: 0px;
                    color: #fff;
                    font-weight: 600;
                    font-size: 1rem;
                    margin-top: 4em;
                    cursor: pointer;
                    }
.btn-send-message:hover{
    text-decoration: none;
    color: #fff;
}

.contact-with-btn1{
                   background: #EB5F46;
                    border-radius: 15px;
                    padding: 6px 20px;
                    border: 0px;
                    color: #fff;
                    font-weight: 600;
                    font-size: 0.8rem;
                    cursor: pointer;
}

.btn-send-message1{
    display: flex;
    text-align: start;
}
.form-container{
    margin-top:4em;
}
.contact-with-item2{
    margin-left: 10em;
}
.card-txt{
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0px;
    margin: 0px;
    margin-top: 1em;
}
.card-txt1{
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0px;
    margin: 0px;
    margin-top: 0.5em;
}

.contact-with-card:hover{
    background-color: #E9ECED;;
}
@media only screen and (max-width: 850px){
.contact-with-container{
    width: 100% !important;
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
}
.contact-us-body{
    flex-wrap: wrap !important;
}
.contact-with-item1{
    width: 100% !important;
    padding-left: 0em !important;
}
.contact-with-item2{
    width: 100% !important;
    margin-left: 0em !important;
    display: flex;
}
.contact-with-header{
    font-size: 1.6rem !important;
    padding-left: 0em !important;
    text-align: center !important;
}
.form-container{
    margin-top: 2em !important;
}
.btn-send-message{
    margin-top: 3em !important;
    font-size: 0.8rem !important;
    padding: 8px 32px  !important;
}
.contact-with-card{
    padding: 0.5em !important;
    width: 10em !important;
    height: 6em;
    border-radius: 8px !important;
}
.contact-with-card img{
    height: 8px !important;
    width: 10px !important;
}
.card-txt{
    font-size: 0.7rem !important;
    margin-top: 0.4em !important;
}
.card-txt1{
    font-size: 0.4rem !important;
    margin-top: 0em !important;
}
.contact-with-btn1{
    
    font-size: 0.5rem !important;
    padding: 4px 10px !important;
}
.txt-extra-info{
    font-size: 0.4rem !important;
    display: none;
}
}
