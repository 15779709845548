.about-us-wrapper{
    background-image: url("./about-us-background.png");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    background-size: 100% 100%;
}
.about-container{
   display: flex;
   flex-direction: row;
}
.about-container-item{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
    justify-content: center;
    padding-left: 8em;
    letter-spacing: 0.08em;
    padding-bottom: 7em;
}
.about-container-img{
    padding-right: 2em;

}
.about-img{
    height: 32em;
    width: 28em;
}
.about-content1{
    font-weight: 700;
}
.about-content2{
    font-size: 2.2rem;
    background-color: #EB5F46;
    padding: 0px 8px;
    padding-right: 20px;
    color: #fff;
}
.about-content3{
    text-align: start;
    letter-spacing: 0.0em;
    padding-right: 4em;
    word-spacing: 0.01em;
    margin-top: 1em;
    margin-bottom: 1em;
}
.order-now-btn{
    background: #EB5F46;
    box-shadow: 0px 0px 10px rgba(235, 95, 70, 0.53);
    border-radius: 6px;
    padding: 6px 20px;
    border: 0px;
    color: #fff;
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
}
.btn-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 850px) {
    .about-container{
        flex-wrap: wrap !important;
    }
    .about-container-item{
        width: 100% !important;
        padding-left: 2em !important;
        padding-right: 2em !important;
        align-items: center !important;
        padding-bottom: 2em !important;
    }
    .about-container-img{
        width: 100% !important;
        padding-left: 2em !important;
    }
    .about-content2{
        font-size: 2em !important;
    }
    .about-content3{
        padding-right: 0em !important;
    }
    .order-now-btn{
        margin-top: 2em !important;
    }
    .about-img {
        height: 24em;
        width: 20em;
    }
    .about-us-wrapper{
        padding-bottom: 6em !important;
    }
}